<template>
  <div>
    <v-container>
      <!-- 消息条 -->
      <v-snackbar :timeout="1500" class="mt-4" v-model="notiBar.enabled" top transition="slide-y-transition"
        :color="notiBar.color" center text style="top: 56px">
        {{ notiBar.text }}
      </v-snackbar>

      <!-- 客户端列表区域 -->
      <v-card class="my-3">
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 pl-0"><v-btn depressed to="/"
              class="mr-5"><v-icon>mdi-arrow-left-thick</v-icon>返回</v-btn>用户列表
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn depressed @click="refresh" class="mx-2"><v-icon>mdi-refresh</v-icon></v-btn>
        </v-app-bar>
      </v-card>

      <v-card class="mx-auto pa-5 mt-5">
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 pl-0">用户列表
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="搜索" single-line hide-details></v-text-field>
        </v-app-bar>

        <!-- 表格区 -->
        <v-card-text>
          <v-data-table :headers="headers" :items="list" :search="search" :items-per-page="100" :loading="loading"
            loading-text="正在获取数据。。。" :calculate-widths="false">



            <!-- avatar_url的展示 -->
            <template v-slot:item.avatar_url="{ item }">
              <v-avatar width="45px" height="45px">
                <img :src="item.avatar_url" v-if="item.avatar_url != ''" alt="avatar">
              </v-avatar>
            </template>
            <!-- avatar_url的展示 -->

            <template v-slot:item.actions="{ item }">
              <v-btn class="ma-2" depressed @click="switchToUser(item)">切换<v-icon right dark>
                  mdi-swap-horizontal
                </v-icon></v-btn>
              <v-btn class="ma-2" depressed @click="editUser(item)">查看<v-icon right dark>
                  mdi-magnify
                </v-icon></v-btn>
              <v-btn class="ma-2" depressed @click="deleteUser(item)" color="error">删除<v-icon right dark>
                  mdi-delete
                </v-icon></v-btn>
            </template>
          </v-data-table>
          <v-divider></v-divider>
        </v-card-text>
      </v-card>

      <!-- 编辑框 -->
      <v-dialog v-model="editor.dialog_open" persistent>
        <v-card>
          <v-card-title>{{
            editor.mode == "edit" ? "修改用户信息(ID:" + editor.data.user_id + ")" : "新建用户"
          }}</v-card-title>
          <v-card-text>
            <!-- 编辑框主体部分 -->
            <v-row>
              <v-col><v-text-field label="用户名" outlined v-model="editor.data.user_name"></v-text-field></v-col>
              <v-col><v-radio-group v-model="editor.data.status" row label="帐号状态：">
                  <v-radio label="封禁中" :value="-1"></v-radio>
                  <v-radio label="未激活" :value="0"></v-radio>
                  <v-radio label="正常" :value="1"></v-radio>
                </v-radio-group></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field label="邮箱" outlined v-model="editor.data.email"></v-text-field></v-col>
              <v-col><v-select v-model="editor.data.group" :items="group_list" label="用户群组" outlined></v-select></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field label="Google绑定" v-model="editor.data.google" disabled filled></v-text-field></v-col>
              <v-col><v-text-field label="Wechat绑定" filled v-model="editor.data.wehchat" disabled></v-text-field></v-col>
              <v-col><v-text-field label="Telegram绑定" filled v-model="editor.data.telegram"
                  disabled></v-text-field></v-col>

            </v-row>

            <!-- 编辑框主体部分 -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click="submit">
              {{ editor.mode == "edit" ? "提交修改" : "创建" }}
            </v-btn>
            <v-btn color="red darken-1" text @click="editor.dialog_open = false">
              关闭
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 编辑框 -->

      <!-- 提示框 -->
      <v-dialog v-model="alert.alert" max-width="450px">
        <v-card>
          <v-card-title>{{ alert.alert_type }}</v-card-title>
          <v-card-text>
            {{ alert.alert_text }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="alert.alert = !alert.alert">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- 删除提示框 -->
      <v-dialog v-model="deleteData.dialog" max-width="450px">
        <v-card>
          <v-card-title>确认删除</v-card-title>
          <v-card-text> 请再次确认？ </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="doDelete">删除</v-btn>
            <v-btn color="blue darken-1" text @click="deleteData.dialog = false">取消</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- 切换提示框 -->
      <v-dialog v-model="switchData.dialog" max-width="450px">
        <v-card>
          <v-card-title>确认切换</v-card-title>
          <v-card-text> 请再次确认？ </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="doSwitch">切换</v-btn>
            <v-btn color="darken-1" text @click="switchData.dialog = false">取消</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-container>
  </div>
</template>

<script>
export default {
  name: "adminUser",
  data: function () {
    return {
      search: "",
      headers: [
        {
          text: "列表ID",
          align: "start",
          value: "id",
        },
        { text: "头像", value: "avatar_url" },
        { text: "用户名", value: "user_name" },
        { text: "邮箱", value: "email" },
        { text: "群组", value: "group" },
        { text: "上次登录时间", value: "last_time_login" },
        { text: '操作', value: 'actions', sortable: false },
      ],
      list: [],
      group_list: [],
      group_name_map: null,
      editor: {
        mode: "",
        dialog_open: false,
        data: {
          id: 1,
          user_id: 1,
          status: 1,
          user_name: "admin",
          email: "xxx@gmail.com",
          group: "1",
          group_name: "超级管理员",
          avatar_url: "http://localhost:8990/image/xxx",
          last_time_login: "2024-01-04 21:22:56",
          wechat: "",
          google: "xxx",
          telegram: "xxx"
        },
      },
      loading: false,
      alert: {
        alert_text: "",
        alert_type: "info",
        alert: false,
      },
      notiBar: {
        enabled: false,
        color: "",
        text: "",
      },
      deleteData: {
        dialog: false,
        id: 0,
      },
      switchData: {
        dialog: false,
        id: 0,
      },

      delete_item: null
    };
  },
  mounted: function () {
    this.initData();
  },
  watch: {

  },
  computed: {

  },
  beforeDestroy() {

  },
  methods: {
    initData: function () {
      this.loading = true;
      //获取数据
      this.axios
        .get("/api/admin/listUser")
        .then((res) => {
          if (res.data.status === 0) {
            this.list = res.data.data.list;
            this.group_list = res.data.data.group_list;
          } else {
            this.errorBar(this.parseError(res));
          }
          this.loading = false;
          return;
        })
        .catch((err) => {
          console.log(err);
          this.error("发生错误:" + err);
        });
    },
    submit: function () {
      this.editor.data.own_by = Number(this.editor.data.own_by);
      //发送
      this.axios({
        method: "post",
        url: "/api/admin/editUser",
        data: {
          edit_type: this.editor.mode,
          data: {
            user_id: Number(this.editor.data.user_id),
            status: Number(this.editor.data.status),
            user_name: this.editor.data.user_name,
            email: this.editor.data.email,
            group: this.editor.data.group
          }
        },
      })
        .then((res) => {
          if (res.data.status === 0) {
            this.successBar("操作成功");
            this.initData();
          } else {
            this.error(this.parseError(res));
          }
          this.editor.dialog_open = false;
          return;
        })
        .catch((err) => {
          console.log(err);
          this.error("发生错误:" + err);
        });
    },
    editUser: function (item) {
      console.log("editUser", item);
      this.editor.mode = "edit";
      this.editor.data = this.copy(item);
      this.editor.dialog_open = true;
    },
    deleteUser: function (item) {
      this.deleteData.dialog = true;
      this.deleteData.id = item.user_id;
    },
    doSwitch: function () {
      this.axios({
        method: "post",
        url: "/api/admin/switchToUser",
        data: {
          user_id: this.switchData.id
        },
      })
        .then((res) => {
          if (res.data.status === 0) {
            this.successBar("切换成功");
            setTimeout(() => {
              this.$router.push("/");
            }, 1000);
          } else {
            this.error(this.parseError(res));
          }
          this.switchData.dialog = false;
          return;
        })
        .catch((err) => {
          console.log(err);
          this.error("发生错误:" + err);
        });
    },
    switchToUser: function (item) {
      this.switchData.dialog = true;
      this.switchData.id = item.user_id;
    },
    doDelete: function () {
      this.axios({
        method: "post",
        url: "/api/admin/delUser",
        data: {
          user_id: this.deleteData.id
        },
      })
        .then((res) => {
          if (res.data.status === 0) {
            this.successBar("删除成功");
            this.initData();
          } else {
            this.error(this.parseError(res));
          }
          this.deleteData.dialog = false;
          return;
        })
        .catch((err) => {
          console.log(err);
          this.error("发生错误:" + err);
        });
    },
    show_group_name: function (data) {
      if (data == 0) {
        return "系统"
      }
      var out = this.group_name_map.get(Number(data));
      if (out == null) {
        return "NA"
      }
      return ["【", data, "】", out].join("");
    },
    copy: function (data) {
      var objString = JSON.stringify(data);
      return JSON.parse(objString);
    },
    refresh: function () {
      this.initData();
      this.successBar("刷新成功");
    },
    success: function (text) {
      this.alert.alert = !this.alert.alert;
      this.alert.alert_text = text;
      this.alert.alert_type = "成功";
    },
    error: function (err) {
      this.alert.alert = !this.alert.alert;
      this.alert.alert_text = err;
      this.alert.alert_type = "错误";
    },
    successBar: function (text) {
      this.notiBar.enabled = !this.notiBar.enabled;
      this.notiBar.text = text;
      this.notiBar.color = "success";
    },
    errorBar: function (err) {
      this.notiBar.enabled = !this.notiBar.enabled;
      this.notiBar.text = err;
      this.notiBar.color = "error";
    },
  },
};
</script>

<style></style>
